<template>
	<div class="view-model">
		<button @click="toLink('/obj')">点击切换查看obj</button>
		<button @click="toLink('/stl')">点击切换查看stl</button>
		<button @click="toLink('/mtl')">点击切换查看mtl</button>
		<!-- <router-link to="./asa">123</router-link> -->
		<router-view />

	</div>
</template>

<script>
	export default {
		name: "Demo",
		data() {
			return {

			};
		},

		created() {
			this.toLink('/obj');
		},
		methods: {
			toLink(val) {
				this.$router.push(val);
			},
		}
	};
</script>

<style scoped>
	.view-model {
		height: 100%;
		width: 100%;
		position: relative;
		/* background: #a0a2a6; */
	}

	.conta {
		height: 100%;
		width: 100%;
	}

	/* 进度条 */
	.progress {
		position: fixed;
		width: 100%;
		height: 100%;
	}

	.progress .mask {
		position: fixed;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.2);
		z-index: 99;
	}

	.progress .loading {
		width: 30em;
		height: 1em;
		background: #fff;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 100;
		border-radius: 1em;
	}

	.progress .trends {
		width: 0;
		height: 100%;
		background: rgb(134, 130, 130);
		box-shadow: 1px 1px 10px rgb(100, 98, 98);
		border-radius: 1em;
	}
</style>
